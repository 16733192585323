import React from "react"
import css from "@styled-system/css"
import { useScrollYPosition } from "react-use-scroll-position"

const Logo = props => {
  const scrollY = useScrollYPosition()
  const scrollOffset = 500
  let opacity = props.isOpen ? 1 : 0
  if (scrollY > scrollOffset) {
    opacity = 1
  }
  const atts = {
    d:
      "M0 31.72l.049 16.695h16.206V14.977H0V31.72zm4.54 20.698c-7.566 4.051-4.98 15.279 3.563 15.279 6.2 0 9.959-6.688 6.932-12.155-2.001-3.466-7.03-4.98-10.495-3.124zm16.621-37.45h39.226v16.765h-19.49v5.865h15.777v15.103H40.945V68H21.26l-.098-53.032zM9.378.198v3.375H6.6v6.897H2.78V3.573H0V.198h9.378zm5.012 3.523h2.332V.198h3.82V10.47h-3.82V6.947H14.39v3.523h-3.82V.198h3.82v3.523zM26.1 7.542h4.416v2.928h-8.237V.198h7.94v2.928h-4.12v.744h3.822v2.928H26.1v.744zm15.68-2.431s2.332.347 2.332 2.58c0 1.588-1.34 2.927-4.962 2.927-2.332 0-4.416-.148-4.416-.148V.198S36.768.05 39.05.05c3.274 0 4.614 1.34 4.614 2.877 0 1.737-1.885 2.134-1.885 2.134v.05zm-3.226-1.092h.497c.496 0 .794-.298.794-.645 0-.347-.298-.645-.794-.645h-.497v1.29zm1.489 3.225c0-.446-.298-.744-.893-.744h-.596V7.99h.596c.595 0 .893-.298.893-.745zm9.23 3.226H45.45V.198h3.821V10.47zm7.591-3.374V5.359h3.523v3.573s-1.34 1.736-4.267 1.736c-3.077 0-5.409-2.332-5.409-5.359S53.093 0 56.17 0c2.778 0 4.118 1.588 4.118 1.588L58.55 4.367s-.893-.794-1.885-.794-1.737.744-1.737 1.736c0 .993.744 1.737 1.737 1.737.05.05.198.05.198.05z",
    width: 61,
    height: 68,
  }

  if (atts) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="61"
        height="68"
        fill="none"
        viewBox={`0 0 ${atts.width} ${atts.height}`}
        css={css({
          opacity,
          transition: "opacity 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955)",
        })}
        key={atts}
        {...props}
      >
        <path
          key={atts.d}
          fill={props.color ? props.color : "#fff"}
          d={atts.d}
        ></path>
      </svg>
    )
  }

  return null
}

export default Logo
