import React from "react"
import { ThemeProvider } from "emotion-theming"

import { theme } from "@bigif/theme"

const Root = ({ element }) => (
  <ThemeProvider theme={theme}>
    <>{element}</>
  </ThemeProvider>
)

export default Root
