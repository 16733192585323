const breakpoints = ["40em", "52em", "64em"]
const colors = {
  text: "#fff",
  primary: ["#e09392", "#b62f2e", "#7d1716"],
  secondary: ["#8bd3d2", "#287974", "#236965"]
}
export const theme = {
  colors,
  fontSizes: [
    "1.25rem",
    "1.563rem",
    "1.953rem",
    "2.441rem",
    "3.052rem",
    "5rem",
  ],
  fonts: {
    body: "Josefin Sans, sans-serif",
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: "150%",
  },
  radii: {
    default: 15,
    button: 4,
  },
  shadows: {
    initial: "0 0.25rem 1.25rem rgba(0, 0, 0, 0.00)",
    card: "0 0.25rem 1.25rem rgba(0, 0, 0, 0.35)",
  },
  text: {
    heading: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "heading",
    },
  },
  breakpoints,
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  mediaQueries: {
    small: `@media screen and (min-width: ${breakpoints[0]})`,
    medium: `@media screen and (min-width: ${breakpoints[1]})`,
    large: `@media screen and (min-width: ${breakpoints[2]})`,
  },
}

