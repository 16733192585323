import styled from "@emotion/styled"
import css from "@styled-system/css"
import { variant } from "styled-system"

const Button = styled.button`
  display: block;
  text-align: center;
  transition: box-shadow 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955),
    background-color 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  width: 100%;
  ${css({
    fontSize: [0, 1],
    borderRadius: "button",
    boxShadow: "initial",
    border: "thick solid white",
    fontWeight: "bold",
    py: 3,
    "&:hover": {
      boxShadow: "card",
      cursor: "pointer",
    },
  })}
  ${variant({
    variants: {
      primary: {
        bg: "text",
        color: "primary.1",
        "&:hover": {
          bg: "primary.2",
          color: "text",
        },
      },
      secondary: {
        bg: "text",
        color: "secondary.1",
        "&:hover": {
          bg: "secondary.2",
          color: "text",
        },
      },
      outlinePrimary: {
        backgroundColor: "rgba(0,0,0,0)",
        color: "text",
        display: "inline-block",
        width: "auto",
        p: 2,
        px: 3,
        "&:hover": {
          bg: "primary.1",
        },
      },
      smPrimary: {
        bg: "text",
        color: "primary.1",
        borderColor: "primary.1",
        display: "inline-block",
        width: "auto",
        p: 2,
        px: 3,
        "&:hover": {
          bg: "primary.1",
          borderColor: "primary.1",
          color: "text",
        },
      },
      none: {
        backgroundColor: "rgba(0,0,0,0)",
        border: "none",
        color: "text",
        display: "inline-block",
        width: "auto",
        p: 2,
      },
    },
  })}
`

export default Button
