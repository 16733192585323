import React from "react"
import Helmet from "react-helmet"
import VisuallyHidden from "@reach/visually-hidden"

import SEO from "./seo"
import Header from "./header"
import Pixels from "./pixels"
import Style from "./style"

export { default as Root } from "./root"

const Layout = ({ children, title }) => (
  <>
    <Pixels />
    <VisuallyHidden>
      <a href="#main">Skip to content</a>
    </VisuallyHidden>
    <Style />
    <SEO title={title} />
    <Header />
    <main id="main">{children}</main>
  </>
)

export default Layout
