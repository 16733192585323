import React from "react"
import styled from "@emotion/styled"
import css from "@styled-system/css"
import { useTheme } from "emotion-theming"
import { rgba } from "polished"
import { Box } from "reflexbox"
import { useScrollYPosition } from "react-use-scroll-position"
import { DialogContent, DialogOverlay } from "@reach/dialog"

import LogoIcon from "@bigif/svgs/logo"
import LogoFrIcon from "@bigif/svgs/logo-fr"

const scrollOffset = 500

export const Wrapper = props => {
  const scrollY = useScrollYPosition()
  const theme = useTheme()
  let bg = "rgba(0,0,0,0)"
  let boxShadow = "initial"
  if (scrollY > scrollOffset) {
    bg = props.isOpen ? "text" : rgba(theme.colors.primary[1], 0.9)
    boxShadow = props.isOpen ? "initial" : "card"
  }
  return (
    <Box
      css={css({
        alignItems: "center",
        bg,
        boxShadow,
        display: "flex",
        left: 0,
        justifyContent: "space-between",
        position: "fixed",
        top: 0,
        width: "100%",
        transition:
          "background-color 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955), box-shadow 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955)",
        zIndex: 999,
      })}
      {...props}
      as="header"
    />
  )
}

export const Logo = props => {
  const scrollY = useScrollYPosition()
  let opacity = props.isOpen ? 1 : 0
  if (scrollY > scrollOffset) {
    opacity = 1
  }
  return (
    <LogoIcon
      color={props.color}
      css={css({
        opacity,
        transition: "opacity 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955)",
      })}
    />
  )
}

export const LogoFr = props => {
  const scrollY = useScrollYPosition()
  let opacity = props.isOpen ? 1 : 0
  if (scrollY > scrollOffset) {
    opacity = 1
  }
  return (
    <LogoFrIcon
      color={props.color}
      css={css({
        opacity,
        transition: "opacity 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955)",
      })}
    />
  )
}

export const MenuLink = styled.button`
  ${css({
    background: "none",
    border: "none",
    fontSize: [2, 3, 4],
    color: "primary.1",
    display: "block",
    mb: 3,
    py: 3,
    width: "100%",
    "&:hover": {
      color: "primary.2",
      cursor: "pointer",
    },
    "&:focus": {
      outline: "solid medium",
      outlineColor: "primary.0",
    },
  })}
`

export const Content = styled(DialogContent)`
  background: none !important;
  margin: 0;
  padding: 0;
  width: 100%;

  & > div > ${MenuLink}:last-child {
    border-bottom: none !important;
  }
`

export const Overlay = styled(DialogOverlay)`
  ${css({
    alignItems: "center",
    bg: "text",
    display: "flex",
    justifyContent: "center",
    zIndex: 998,
  })}
`
