import React from "react"

const Burger = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="32"
      fill="none"
      viewBox="0 0 60 32"
      {...props}
    >
      <path
        fill={props.color ? props.color : "#fff"}
        d="M0 0h60v2.581H0zm0 14.968h60v2.581H0zm0 14.451h60V32H0z"
      ></path>
    </svg>
  )
}

export default Burger
