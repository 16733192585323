import styled from "@emotion/styled"
import { Box } from "reflexbox"

const Container = styled(Box)`
  max-width: ${props => props.maxWidth};
`

Container.defaultProps = {
  mx: "auto",
  px: 3,
  maxWidth: "900px",
}

export default Container
