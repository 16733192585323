import React from "react"
import { Global, css } from "@emotion/core"

const Style = () => (
  <Global
    styles={theme => css`
      body {
        background-color: ${theme.colors.primary[1]};
        color: ${theme.colors.text};
        font-family: ${theme.fonts.body};
        margin: 0;
        padding: 0;
      }
      p {
        line-height: 150%;
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-weight: ${theme.fontWeights.heading};
      }
      h1 {
        font-size: ${theme.fontSizes[5]};
      }
      h2 {
        font-size: ${theme.fontSizes[4]};
      }
      h3 {
        font-size: ${theme.fontSizes[3]};
      }
      h4 {
        font-size: ${theme.fontSizes[2]};
      }
      h5 {
        font-size: ${theme.fontSizes[1]};
      }
      h6 {
        font-size: ${theme.fontSizes[0]};
      }
      a {
        text-decoration: none;
      }

      [data-reach-menu-item] {
        color: ${theme.colors.secondary[1]};
        &[data-selected] {
          background: ${theme.colors.secondary[1]};
          color: ${theme.colors.text};
          padding: ${theme.space[4]} ${theme.space[5]};
        }
      }
    `}
  />
)

export default Style
